// React imports
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react'

// MUI imports
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  List,
  ListItem,
  Modal,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

// Local component imports
import Center from '/src/components/center'
import PrimaryButton from '/src/components/buttons/primary-button'
import TextButton from '/src/components/buttons/text-button'

// Local utility imports
import { tutorials } from '/src/constants/tutorials'
import usersRepo from '/src/data/repos/users-repo'
import { useUser } from '/src/hooks/use-user'

const TutorialContext = createContext({
  openTutorial: () => {},
  closeTutorial: () => {}
})

const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = resolve
    img.onerror = reject
  })
}

const preloadVideo = (src) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = src
    video.onloadeddata = resolve
    video.onerror = reject
  })
}

const TutorialModal = ({ tutorialId, isRequired, onClose, isOpen }) => {
  const [tutorial, setTutorial] = useState({
    page: 0
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { user, updateUser } = useUser()
  // Video loading states
  const [isLoading, setIsLoading] = useState(true)

  const pages = useMemo(
    () => tutorials[tutorialId]?.contents || [],
    [tutorialId]
  )

  useEffect(() => {
    if (isOpen) {
      setTutorial({
        page: 0
      })
    }
  }, [isOpen, setTutorial])

  // Add this new useEffect for preloading
  useEffect(() => {
    const preloadNextPage = async () => {
      const nextPage = pages[tutorial.page + 1]
      if (nextPage) {
        try {
          await (nextPage.videoUrl
            ? preloadVideo(nextPage.videoUrl)
            : preloadImage(nextPage.imageSrc))
        } catch (error) {
          console.warn('Failed to preload next page:', error)
        }
      }
    }

    preloadNextPage()
  }, [tutorial.page, pages])

  // Simplify handleNext since preloading is handled separately
  const handleNext = async () => {
    if (tutorial.page === pages.length - 1) {
      setIsSubmitting(true)
      if (isRequired) {
        try {
          const result = await usersRepo.updateTutorials({
            name: tutorialId,
            completed: true
          })
          updateUser({
            ...user,
            tutorials: result
          })
        } catch (error) {
          console.warn(error)
        }
      }
      setIsSubmitting(false)
      onClose()
      return
    }

    setIsLoading(true)
    setTutorial((prevState) => ({
      ...prevState,
      page: prevState.page + 1
    }))
  }

  const handlePrev = () => {
    setIsLoading(true)
    if (tutorial.page === 0) return

    setTutorial((prevState) => ({
      ...prevState,
      page: prevState.page - 1
    }))
  }

  const tutorialDetails = pages[tutorial.page]
  const index = tutorial.page
  const length = pages.length

  const handStopLoading = useCallback(() => {
    setIsLoading(false)
  }, [])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={isOpen}>
        <Box
          sx={{
            'position': 'absolute',
            'top': '50%',
            'left': '50%',
            'transform': 'translate(-50%, -50%)',
            'bgcolor': 'background.paper',
            '&:focus': {
              outline: 'none'
            },
            'width': '95%',
            'height': '95%',
            'borderRadius': 2,
            'maxWidth': 2000,
            'maxHeight': 1200
          }}
        >
          <Stack
            sx={{
              height: '100%',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 1,
                width: '100%'
              }}
            >
              <TextButton
                size="large"
                endIcon={
                  <SvgIcon>
                    <ExitToAppIcon />
                  </SvgIcon>
                }
                style={{ visibility: isRequired ? 'hidden' : 'visible' }}
                onClick={onClose}
              >
                Exit
              </TextButton>
            </Box>
            <Stack
              sx={{
                flexDirection: {
                  md: 'column',
                  lg: 'row'
                },
                flexGrow: 1,
                overflow: 'auto'
              }}
            >
              {tutorialDetails?.videoEmbed ? (
                <Box
                  sx={{
                    px: 5,
                    width: '100%',
                    height: '100%',
                    margin: '0 auto'
                  }}
                >
                  <iframe
                    title="Tutorial Video"
                    src={tutorialDetails.videoEmbed}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      borderRadius: '8px'
                    }}
                  ></iframe>
                </Box>
              ) : (
                <>
                  <Stack
                    sx={{
                      p: 10,
                      height: '100%',
                      width: '100%',
                      flexGrow: 1,
                      alignItems: 'start',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography variant="h2">
                      {tutorialDetails?.title || ''}
                    </Typography>
                    <Typography
                      sx={{
                        marginTop: 3
                      }}
                      variant="body1"
                    >
                      {tutorialDetails?.description || ''}
                    </Typography>
                    <List>
                      {tutorialDetails?.bulletPoints?.map((bulletPoint, i) => (
                        <ListItem
                          key={i}
                          sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: 3
                            }}
                            variant="h6"
                          >
                            {bulletPoint?.title || ''}
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: 1
                            }}
                          >
                            {bulletPoint?.description || ''}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: '100%'
                    }}
                  >
                    <Stack
                      sx={{
                        boxShadow: 24,
                        borderRadius: 10,
                        width: '90%',
                        minHeight: 300
                      }}
                    >
                      <Center
                        sx={{
                          position: 'relative'
                        }}
                      >
                        {isLoading ? (
                          <Center
                            sx={{
                              position: 'absolute',
                              zIndex: 1000,
                              height: '100%',
                              width: '100%',
                              // Add blur effect
                              backdropFilter: 'blur(5px)'
                            }}
                          >
                            <CircularProgress />
                          </Center>
                        ) : null}
                        {tutorialDetails?.videoUrl ? (
                          <Box
                            sx={{
                              position: 'relative',
                              width: '100%',
                              height: '100%'
                            }}
                          >
                            {!tutorialDetails.videoUrl && (
                              <Center
                                sx={{
                                  position: 'absolute',
                                  zIndex: 1000,
                                  height: '100%',
                                  width: '100%',
                                  backdropFilter: 'blur(5px)'
                                }}
                              >
                                <CircularProgress />
                              </Center>
                            )}
                            <video
                              width="100%"
                              height="100%"
                              autoPlay
                              loop
                              volume={1}
                              muted={true}
                              src={tutorialDetails.videoUrl}
                              preload="auto"
                              onCanPlay={handStopLoading}
                            >
                              <source
                                src={tutorialDetails.videoUrl}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </Box>
                        ) : (
                          <img
                            src={tutorialDetails?.imageSrc || ''}
                            alt={tutorialDetails?.imageAlt || ''}
                            style={{
                              width: '100%',
                              height: '100%'
                            }}
                            onLoad={handStopLoading}
                          />
                        )}
                      </Center>
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              m={3}
            >
              <PrimaryButton
                color="primary"
                onClick={handlePrev}
                style={{ visibility: index === 0 ? 'hidden' : 'visible' }}
              >
                Previous
              </PrimaryButton>

              <Typography sx={{ fontSize: 16 }}>{`${
                index + 1
              } of ${length}`}</Typography>

              <PrimaryButton
                color="primary"
                onClick={handleNext}
                disabled={isSubmitting}
              >
                {index + 1 === length ? 'Complete' : 'Next'}
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}

export const TutorialProvider = ({ children }) => {
  const [tutorialState, setTutorialState] = useState({
    isOpen: false,
    tutorialId: null,
    isRequired: false
  })

  const openTutorial = useCallback((tutorialId, isRequired = false) => {
    setTutorialState({
      isOpen: true,
      tutorialId,
      isRequired
    })
  }, [])

  const closeTutorial = useCallback(() => {
    setTutorialState({
      isOpen: false,
      tutorialId: null,
      isRequired: false
    })
  }, [])

  const contextValue = useMemo(
    () => ({
      openTutorial,
      closeTutorial
    }),
    [openTutorial, closeTutorial]
  )

  return (
    <TutorialContext.Provider value={contextValue}>
      {children}
      <TutorialModal
        tutorialId={tutorialState.tutorialId}
        isRequired={tutorialState.isRequired}
        onClose={closeTutorial}
        isOpen={tutorialState.isOpen}
      />
    </TutorialContext.Provider>
  )
}

export const useTutorialContext = () => {
  const context = useContext(TutorialContext)
  if (!context) {
    throw new Error('useTutorialContext must be used within a TutorialProvider')
  }
  return context
}
