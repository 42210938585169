import XIcon from '@untitled-ui/icons-react/build/cjs/X'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  styled,
  AccordionProps,
  AccordionSummaryProps,
  Theme
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Scrollbar } from '/src/components/scrollbar'
import { useEffect, useState } from 'react'
import { tutorials } from '/src/constants/tutorials'
import { MenuBook } from '@mui/icons-material'
import { useUser } from '/src/hooks/use-user'
import faqs from '/src/constants/faqs'
import SecondaryButton from '/src/components/buttons/secondary-button'
import { convertToSlug } from '/src/utils/convert-to-slug'
import { UserInterface } from '/types/user'
import { useTutorial } from '/src/hooks/use-tutorial'

interface FAQ {
  faqId: string
  question: string
  answer: string
  title?: string
  description?: string
}

interface Tutorial {
  title: string
  role: string
  contents: {
    title: string
    description: string
    bulletPoints: {
      title: string
      description: string
    }[]
  }[]
}

interface TutorialMap {
  [key: string]: Tutorial
}

interface HelpDrawerProps {
  open: string | null
  handleClose: (arg: { faqDrawerOpen: null }) => void
}

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} {...props} />
))(({ theme }: { theme: Theme }) => ({
  boxShadow:
    theme.palette.mode === 'dark' ? theme.shadows[20] : theme.shadows[5]
}))

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }: { theme: Theme }) => ({
  '&.Mui-expanded': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: '10px 10px 0 0'
  },
  'padding': '15px',
  '&:hover': {
    'backgroundColor': theme.palette.action.hover,
    'borderRadius': '10px',

    '&.Mui-expanded': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: '10px 10px 0 0'
    }
  }
}))

const CustomAccordionDetails = styled(AccordionDetails)(
  ({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(3),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
  })
)

export const HelpDrawer: React.FC<HelpDrawerProps> = ({
  open,
  handleClose
}) => {
  // get user
  const { user } = useUser() as any

  // Filter tutorials based on user role
  const tutorialsForUser: TutorialMap = Object.entries(tutorials).reduce(
    (acc: TutorialMap, [tutorialId, tutorialData]: [string, Tutorial]) => {
      if (
        user &&
        (user as UserInterface).userRoles.includes(tutorialData.role)
      ) {
        acc[tutorialId] = tutorialData
      }
      return acc
    },
    {}
  )

  // Search bar state and handler
  const [filteredFaqs, setFilteredFaqs] = useState<FAQ[]>([])
  const [filteredTutorials, setFilteredTutorials] =
    useState<TutorialMap>(tutorialsForUser)

  const { showTutorial } = useTutorial()

  useEffect(() => {
    setFilteredFaqs(faqs)
  }, [])

  const handleSearch = (value: string) => {
    // Split words to search
    const searchWords = value
      .toLowerCase()
      .split(' ')
      .map((word) => word.trim())

    // Search word frequency
    const faqsWithFrequency = faqs.map((faq: FAQ) => {
      const faqText = (faq.question + ' ' + faq.answer).toLowerCase()
      let frequency = 0
      searchWords.forEach((word) => {
        const regex = new RegExp(`${word}`, 'gi')
        const matches = (faqText.match(regex) || []).length
        frequency += matches
      })
      return { ...faq, frequency }
    })
    // Sort FAQs based on frequency of match, ignore if 0.
    const sortedFaqs = faqsWithFrequency
      .filter((faq) => faq.frequency > 0)
      .sort((a, b) => b.frequency - a.frequency)
    setFilteredFaqs(sortedFaqs)

    // Filter tutorials based on match
    const filteredTutorials = Object.entries(tutorialsForUser).filter(
      ([_, tutorialData]: [string, Tutorial]) => {
        const tutorialText = tutorialData.contents
          ?.map(
            (tutorialPage) =>
              (tutorialData.title || '') +
              ' ' +
              (tutorialPage.title || '') +
              ' ' +
              (tutorialPage.description || '') +
              ' ' +
              (tutorialPage.bulletPoints
                ?.map(
                  (bulletPoint) =>
                    (bulletPoint.title || '') +
                    ' ' +
                    (bulletPoint.description || '')
                )
                .join(' ') || '')
          )
          .join(' ')
          .toLowerCase()
        let frequency = 0
        searchWords.forEach((word) => {
          const regex = new RegExp(`${word}`, 'gi')
          const matches = (tutorialText.match(regex) || []).length
          frequency += matches
        })
        // Fail if frequency < 0
        return frequency > 0
      }
    )
    setFilteredTutorials(Object.fromEntries(filteredTutorials))
  }

  // Handle FAQ collapse and expand
  const [expandedKey, setExpandedKey] = useState<string | null>(open)
  const handleExpandedKey = (key: string) => {
    setExpandedKey((prev) => (prev === key ? null : key))
  }

  useEffect(() => {
    // this helps to scroll to the expanded accordion when the drawer is opened for the first time
    if (expandedKey === null) {
      setExpandedKey(open)
    }

    // this helps to scroll to the expanded accordion after the drawer is opened once and then closed
    if (open === null && expandedKey !== null) {
      setExpandedKey(null)
    }
    // this helps to scroll to the expanded accordion when the drawer is opened by clicking on the any i icon on the pages
    if (open === expandedKey && open !== null) {
      document.getElementById(open)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }, [open, expandedKey])

  const handleCloseDrawer = () => {
    setFilteredFaqs(faqs)
    setFilteredTutorials(tutorialsForUser)
    handleClose({ faqDrawerOpen: null })
  }

  return (
    <Drawer
      disableScrollLock
      anchor="right"
      open={!!open}
      ModalProps={{
        onClose: () => handleCloseDrawer()
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          maxWidth: '100%',
          width: 550
        }
      }}
    >
      <Scrollbar
        sx={{
          'height': '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            px: 3,
            py: 2
          }}
        >
          {/* Search bar */}
          <Stack
            alignItems="center"
            direction="row"
            spacing={0.5}
            width={'100%'}
          >
            <TextField
              variant="outlined"
              size="medium"
              placeholder="Search tutorials and FAQs..."
              onChange={(event) => handleSearch(event.target.value)}
              sx={{ flexGrow: 1 }}
              data-gaid="faq-search-bar"
            />
            <IconButton color="inherit" onClick={() => handleCloseDrawer()}>
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        {/* Tutorials */}
        <Typography
          variant="h6"
          sx={{
            padding: 3,
            paddingBottom: 0
          }}
        >
          Tutorials
        </Typography>
        <Stack spacing={2} padding={3}>
          {Object.keys(filteredTutorials).map((tutorialId) => (
            <div key={tutorialId}>
              <SecondaryButton
                data-gaid={`${tutorialId}-tutorial-button`}
                onClick={() =>
                  showTutorial({
                    tutorialId
                  })
                }
                startIcon={<MenuBook />}
                sx={{
                  padding: 2,
                  paddingLeft: 3,
                  width: '100%',
                  borderRadius: 1,
                  justifyContent: 'flex-start'
                }}
              >
                {filteredTutorials[tutorialId].title}
              </SecondaryButton>
            </div>
          ))}
          {Object.keys(filteredTutorials)?.length === 0 ? (
            <Typography variant="subtitle2" fontWeight={'400'}>
              We couldn't find any relevant tutorials. Please try a different
              search.
            </Typography>
          ) : null}
        </Stack>
        <Divider />
        {/* FAQs */}
        <Typography
          variant="h6"
          sx={{
            padding: 3,
            paddingBottom: 0
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Stack spacing={2} sx={{ p: 3 }}>
          {filteredFaqs?.map((faq, index) => (
            <CustomAccordion
              key={index}
              expanded={expandedKey === faq.faqId}
              onChange={() => handleExpandedKey(faq.faqId)}
              data-gaid={`faq-question-${convertToSlug(faq.faqId)}`}
            >
              <CustomAccordionSummary id={`faq-question-${faq.question}`}>
                <Typography variant="subtitle1">
                  {faq.question || faq.title}
                </Typography>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                <Typography variant="body2">
                  {faq.answer || faq.description}
                </Typography>
              </CustomAccordionDetails>
            </CustomAccordion>
          ))}
          {filteredFaqs?.length === 0 ? (
            <Typography variant="subtitle2" fontWeight={'400'}>
              We couldn't find any relevant FAQs. Please try a different search.
            </Typography>
          ) : null}
        </Stack>
        {/* Add contact section at the bottom */}
        <Divider />
        <Stack
          spacing={1}
          sx={{
            p: 3,
            textAlign: 'center'
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            Can't find what you're looking for?
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Contact us at{' '}
            <a href="mailto:support@incompass-labs.com">
              support@incompass-labs.com
            </a>
          </Typography>
        </Stack>
      </Scrollbar>
    </Drawer>
  )
}
