import { array, object, string } from 'yup'

export const createFinchConnectSession = object()
  .shape({
    connectUrl: string().required()
  })
  .noUnknown()

export const getFinchEmployerData = object().shape({
  employees: array().of(object())
})
