import { useTutorialContext } from '/src/contexts/tutorial-context'

export const useTutorial = () => {
  const { openTutorial, closeTutorial } = useTutorialContext()

  const showTutorial = ({ tutorialId, isRequired = false }) => {
    openTutorial(tutorialId, isRequired)
  }

  return {
    showTutorial,
    closeTutorial
  }
}
