const featuresMap = Object.freeze({
  GOALS: 'goals',
  SINGULAR_DATA_INGESTION: 'singularDataIngestion',
  BULK_DATA_INGESTION: 'bulkDataIngestion',
  LEADERSHIP_BEHAVIORS: 'leadershipBehaviors',
  LIMIT_FEEDBACK_RECEIVERS: 'limitFeedbackReceivers',
  DEVELOPMENT_PLAN: 'developmentPlan',
  REWARD_ALLOCATION: 'rewardAllocation',
  FINCH_INTEGRATION: 'finchIntegration'
})

export { featuresMap }
