import { COMPLETION_STATES } from '/src/constants/statuses'
import { USER_KEYS } from '/src/constants/user'
import { createObjectFromArrayOfObjects } from '/src/utils/create-object-from-array'
import { createObjectFromKeys } from '/src/utils/create-object-from-keys'

class StatsModal {
  #getPercentage = (amount, totalAmount) => {
    return Number(((amount / totalAmount) * 100).toFixed(1))
  }

  #addReviewCompletionStatus = (stats) => {
    const usersCompleteStatus = []

    if (
      stats.usersNotStartedResponses &&
      stats.usersPartiallyCompletedResponses &&
      stats.usersCompletedResponses
    ) {
      stats.usersNotStartedResponses.forEach((user) => {
        usersCompleteStatus.push({
          ...user,
          reviewCompletionStatus: COMPLETION_STATES.NOT_STARTED
        })
      })
      stats.usersPartiallyCompletedResponses.forEach((user) => {
        usersCompleteStatus.push({
          ...user,
          reviewCompletionStatus: COMPLETION_STATES.PARTIALLY_COMPLETED
        })
      })
      stats.usersCompletedResponses.forEach((user) => {
        usersCompleteStatus.push({
          ...user,
          reviewCompletionStatus: COMPLETION_STATES.COMPLETED
        })
      })
    }

    return usersCompleteStatus
  }

  #checkEmptyFields = (stats) => {
    // get user fields
    const {
      GENDER,
      JOIN_DATE,
      SENIORITY,
      JOB_LEVEL,
      RACE,
      JOB_TITLE,
      DIVISION
    } = USER_KEYS

    const mappedStats = stats.map((user) => {
      return {
        ...user,
        fullName: user.fullName,
        team: user.team,
        division: user.division,
        manager: user.manager,
        location: user.location,
        // optional fields
        ...createObjectFromKeys({
          object: user,
          keys: [
            DIVISION,
            GENDER,
            JOIN_DATE,
            SENIORITY,
            JOB_LEVEL,
            RACE,
            JOB_TITLE
          ]
        })
      }
    })

    return mappedStats
  }

  #prepareCompletionGraphData = (stats) => {
    try {
      // prepare completionStats
      const completionStats = {
        completionGraphData: [],
        dataCategories: []
      }
      let dailyIncurredTotal = []
      let dailyCompletion = []

      // Completion stats
      dailyCompletion = stats.completionGraphStats.map(
        (daily) => daily.completion
      )

      // Create an array of dates in the format of 'dd MMM'
      completionStats.dataCategories = stats.completionGraphStats.map(
        (date) => {
          const parsedDate = new Date(
            date.timestamp._seconds * 1000 +
              date.timestamp._nanoseconds / 1000000
          )
          return (
            parsedDate.toLocaleString('en', { day: '2-digit' }) +
            ' ' +
            parsedDate.toLocaleString('en', { month: 'short' })
          )
        }
      )

      // Getting the daily incurred total
      dailyIncurredTotal = dailyCompletion?.map((daily, i, arr) => {
        return arr.slice(0, i + 1).reduce((acc, cur) => acc + cur, 0)
      })

      completionStats.completionGraphData = [
        {
          name: 'Total Completion',
          data: dailyIncurredTotal
        },
        {
          name: 'Daily Completion',
          data: dailyCompletion
        }
      ]

      return completionStats
    } catch (error) {
      console.warn("Couldn't prepare completion stats:", error)
      return null
    }
  }

  #prepareCompletionStats = ({ divisionsMap, locationsMap }) => {
    const completionStats = {}

    try {
      // Division completion stats
      if (Object.keys(divisionsMap).length) {
        completionStats.division = Object.entries(divisionsMap)
          .map(([division, { total, completed }], i) => {
            return {
              id: i,
              type: division,
              amount: this.#getPercentage(completed, total)
            }
          })
          .sort((a, b) => b.amount - a.amount)
      }

      // Location completion stats
      if (Object.keys(locationsMap).length) {
        completionStats.location = Object.entries(locationsMap)
          .map(([location, { total, completed }], i) => {
            return {
              id: i,
              type: location,
              amount: this.#getPercentage(completed, total)
            }
          })
          .sort((a, b) => b.amount - a.amount)
      }

      return completionStats
    } catch (error) {
      console.warn("Couldn't prepare completion stats:", error)
      return null
    }
  }

  // Calculating the review stats (manager and admin)
  #calculateReviewStats = ({ users, evaluation, reviews }) => {
    // User variables are created
    const totalUsers = users.length

    const divisionsMap = {}
    const locationsMap = {}

    // Iterating through the users and creating the division and location maps to calculate the amount of completion total users
    for (const user of users) {
      if (user.division) {
        if (divisionsMap[user.division]) {
          divisionsMap[user.division].total += 1
        } else {
          divisionsMap[user.division] = {
            total: 1,
            completed: 0
          }
        }
      }
      if (user.location) {
        if (locationsMap[user.location]) {
          locationsMap[user.location].total += 1
        } else {
          locationsMap[user.location] = {
            total: 1,
            completed: 0
          }
        }
      }
    }

    // ----------- REVIEWS ------------------

    // Variables are created

    const usersCompletedResponses = []
    const usersPartiallyCompletedResponses = []
    const usersNotStartedResponses = []

    const usersMap = createObjectFromArrayOfObjects({
      array: users,
      fieldToKey: 'uid'
    })

    // Iterating through the reviews
    for (const review of reviews) {
      let responsePartiallyCompleted = false
      let responsesCompleted = false
      let currentUser = usersMap[review.reviewer]

      if (review.status === 'COMPLETED') {
        if (currentUser.division)
          divisionsMap[currentUser.division].completed += 1

        if (currentUser.location)
          locationsMap[currentUser.location].completed += 1
        responsesCompleted = true
      } else if (review.responses?.[0]?.feedbacks.length > 0) {
        responsePartiallyCompleted = true
      } else {
        responsesCompleted = false
      }

      currentUser = {
        ...currentUser,
        reviewId: review.reviewId
      }

      // Increase the unique number of users completed the responses
      if (responsesCompleted) {
        // Collect users that completed responses
        usersCompletedResponses.push(currentUser)
      } else if (responsePartiallyCompleted) {
        // Collect users that partially completed responses
        usersPartiallyCompletedResponses.push(currentUser)
      } else {
        // Collect users that did not started responses
        usersNotStartedResponses.push(currentUser)
      }
    }

    const completionStats = this.#prepareCompletionStats({
      divisionsMap,
      locationsMap
    })

    // Creating the stats response
    return {
      totalUsers: totalUsers,
      usersCompletedResponses: usersCompletedResponses,
      usersPartiallyCompletedResponses: usersPartiallyCompletedResponses,
      usersNotStartedResponses: usersNotStartedResponses,
      completionGraphStats: evaluation.completionStats,

      // Conditional properties: division, location
      completionStats: completionStats
    }
  }

  prepareForCharts = (data) => {
    // Calculating the review stats
    const stats = this.#calculateReviewStats(data)

    //  Formatting the data by creating one array of users
    const usersCompleteStatus = this.#addReviewCompletionStatus(stats)

    // Prepare the completion stats for the completion graph
    const preparedCompletionGraphStats = this.#prepareCompletionGraphData(stats)

    // check empty fields in usersCompleteStatus
    const checkedUsersWithStatus = this.#checkEmptyFields(usersCompleteStatus)

    // Return the parsed stats object
    return {
      usersCompleteStatus: checkedUsersWithStatus,
      completionGraphStats: preparedCompletionGraphStats || null,
      completionStats: stats.completionStats,
      usersCompletedResponses: stats.usersCompletedResponses,
      usersPartiallyCompletedResponses: stats.usersPartiallyCompletedResponses,
      usersNotStartedResponses: stats.usersNotStartedResponses,
      totalUsers: stats.totalUsers
    }
  }
}

const statsModal = new StatsModal()

export default statsModal
